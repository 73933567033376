// src/pages/Service.js
import React from 'react';
//import ServicePage from '../Components/Services/ServicePage';
import Offerings from '../Components/Home/Offerings';

const Service = () => {
  return (
    <div>
        <Offerings />
      
    </div>
  );
};

export default Service;
