// src/components/Offerings.js
import React from 'react';
import { Box, Grid, Typography, Card, CardContent, CardMedia } from '@mui/material';
import brandDiversityImage from '../../assets/offerings/brand-diversity.png';
import promptDeliveryImage from '../../assets/offerings/prompt-delivery.png';
import competitivePricingImage from '../../assets/offerings/competitive-pricing.jpg';
import customerServiceImage from '../../assets/offerings/customer-service.jpg';
import productQuality from '../../assets/offerings/productQuality.jpg';
import productRange from '../../assets/offerings/RangeProducts.jpg';
const offerings = [
  {
    image: brandDiversityImage,
    title: 'Brand Diversity',
    description: 'Explore a diverse range of renowned product brands, ensuring your customers enjoy a selection tailored to their tastes.',
  },
  {
    image: promptDeliveryImage,
    title: 'Prompt Delivery',
    description: 'Our efficient delivery system guarantees your products arrive promptly, meeting your demands without delay.',
  },
  {
    image: competitivePricingImage,
    title: 'Competitive Pricing',
    description: 'Benefit from competitive prices that fit your budget while maintaining the exceptional quality of our product selections.',
  },
  {
    image: customerServiceImage,
    title: 'Exceptional Customer Service',
    description: 'Our friendly and knowledgeable team is dedicated to providing unparalleled customer service, ensuring your satisfaction with every order.',
  },
  {
    image: productQuality, // Replace with relevant image
    title: 'Product Quality Assurance',
    description: 'We guarantee the freshness and superior quality of all our brands, ensuring customer satisfaction every time.',
  },
  {
    image: productRange, // Replace with relevant image
    title: 'Wide Range of Products',
    description: 'Explore a wide variety of products, ensuring your business finds everything it needs in one place.',
  },
];

const Offerings = () => {
  return (
    <Box sx={{ py: 15, px: 2, backgroundColor: '#ffffff' }}>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        OUR OFFERINGS
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {offerings.map((offering, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                height="330"
                image={offering.image}
                alt={offering.title}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {offering.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {offering.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Offerings;
