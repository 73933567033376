// src/pages/Product.js
import React from 'react';
import ProductGallery from '../Components/Products/ProductGallery';

const Product = () => {
  return (
    <div>
      <ProductGallery/>
    </div>
  );
};

export default Product;
