// src/components/CTA.js
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const CTA = () => {
  return (
    <Box sx={{ py: 15, px: 3, backgroundColor: '#f5f5f5', textAlign: 'center' }}>
      <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#333333' }}>
        Ready to Elevate Your Business?
      </Typography>
      <br/>
      <Typography variant="body1" paragraph sx={{ color: '#666666' }}>
        Contact us today to discover how we can meet your wholesale household products needs.
      </Typography>
      <br/>
      <Button
        component={Link}
        to="/contact"
        variant="contained"
        color="primary"
        sx={{ bgcolor: '#007bff', '&:hover': { bgcolor: '#0056b3' } }}
      >
        Contact Us
      </Button>
    </Box>
  );
};

export default CTA;
