import React from "react";
import { Link } from "react-router-dom";

import "./Error.css";

const Error = () => {
  return (
    <div className="error-container">
      <div className="error-content">
        <h1>OOPS!</h1>
        <h3>Page Not Found</h3>
        <p>
          Sorry, we couldn't find the page you were looking for. We suggest that you<br />
          return to the homepage.
        </p>
        <Link to="/" className="error-link">Go Back</Link>
      </div>
    </div>
  );
};

export default Error;
