// src/pages/AboutUs.js
import React from 'react';
import WhyUs from '../Components/Home/WhyUs';
import Story from '../Components/Home/Story';
import ProductGallery from '../Components/Products/ProductGallery';

const AboutUs = () => {
  return (
    <div>
        <Story />
        <WhyUs/>
        <ProductGallery />
    </div>
  );
};

export default AboutUs;
