

// src/pages/PrivacyPolicy.js
import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" gutterBottom>
        Effective Date: 12 July 2024
      </Typography>

      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to 500% SPRL. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at support@500sprl.co.uk.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect personal information that you voluntarily provide to us when you register on the website, express an interest in obtaining information about us or our products and services, when you participate in activities on the website or otherwise when you contact us.
        </Typography>
        <Typography variant="body1" paragraph>
          The personal information that we collect depends on the context of your interactions with us and the website, the choices you make, and the products and features you use. The personal information we collect can include the following: name, email address, phone number, and mailing address.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use personal information collected via our website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect or receive:
          <ul>
            <li>To facilitate account creation and logon process.</li>
            <li>To send administrative information to you for business purposes, legal reasons, and/or possibly for contractual reasons.</li>
            <li>To fulfill and manage your orders.</li>
            <li>To post testimonials with your consent.</li>
            <li>To send you marketing and promotional communications.</li>
          </ul>
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          4. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          5. Security of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          6. Your Privacy Rights
        </Typography>
        <Typography variant="body1" paragraph>
          In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right to (i) request access and obtain a copy of your personal information, (ii) request rectification or erasure; (iii) restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          7. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have questions or comments about this policy, you may contact us by email at support@500sprl.com or by post to:
        </Typography>
        <Typography variant="body1">
          500% SPRL<br />
          74 Oxford Road<br />
          Denham, Uxbridge, England, UB9 4DN<br />
        </Typography>
      </Box>
    </Container>
    </>
  );
};

export default PrivacyPolicy;

