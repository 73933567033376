import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import productImages from '../../assets/Product/productImages';

const ProductGallery = () => {
  return (
    <>
      <br />
      <br />
      <Container sx={{ py: 8 }}>
        <Typography variant="h2" align="center" gutterBottom>
          Explore Our Product Range
        </Typography>
        <Typography variant="h5" align="center" gutterBottom>
          Wide variety of popular household goods brands.
        </Typography>
        <Typography variant="h5" align="center" gutterBottom>
          Timely delivery services to local businesses, restaurants, and events.
        </Typography>
        <Typography variant="h5" align="center" gutterBottom>
          Affordable rates without compromising quality.
        </Typography>
        <br />
        <br />
        <br />
        <Grid container spacing={4}>
          {productImages.map((product, index) => (
            <Grid item xs={6} sm={6} md={4} lg={4} key={index}>
              <Card
                sx={{
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 3,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="350"
                  image={product.src}
                  alt={product.title}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default ProductGallery;
