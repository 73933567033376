// src/pages/Home.js
import React from 'react';
import Hero from '../Components/Home/Hero'
import WhyUs from '../Components/Home/WhyUs';

import Story from '../Components/Home/Story';
import Offerings from '../Components/Home/Offerings';
import Reviews from '../Components/Home/Reviews';


const Home = () => {
  return (
    <div>
      <Hero />
      <WhyUs />
      <Story />
      <Offerings />
      <Reviews />
    </div>
  );
};

export default Home;
