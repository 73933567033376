// src/components/Story.js
import React from 'react';
import { Box, Grid, Typography, Card, CardMedia } from '@mui/material';
import storyImage from '../../assets/story-image.jpg';  // Replace with your image path

const Story = () => {
  return (
    <Box sx={{ py: 15, px: 2, backgroundColor: '#f9f9f9' }}>
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid item xs={12} md={6}>
          <Card sx={{ maxWidth: 600 }}>
            <CardMedia
              component="img"
              height="auto"
              image={storyImage}
              alt="500% SPRL Story"
              sx={{ objectFit: 'cover' }}
            />
          </Card>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Box sx={{ py: 15, textAlign: 'justify' }}>
            <Typography variant="h4" component="h2" gutterBottom>
              OUR STORY
            </Typography><br/>
            <Typography variant="body1" paragraph>
            500% SPRL was founded with the vision of delivering premium household brands to businesses across Europe at affordable prices, all while providing exceptional service.
            </Typography>
            <Typography variant="body1" paragraph>
              With years of experience in the industry, we bring unmatched expertise to meet your wholesale household products needs. Our dedicated staff is committed to ensuring seamless transactions and top-notch service for our valued customers.
            </Typography>
            <Typography variant="body1">
              Choose 500% SPRL as your partner and elevate your business with our premium products and top-notch service.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
