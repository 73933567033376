// src/assets/Product/productImages.js

import product1 from './Product (1).jpg';
import product2 from './Product (2).jpg';
import product3 from './Product (3).jpg';
import product4 from './Product (4).jpg';
import product5 from './Product (5).jpg';
import product6 from './Product (6).jpg';
import product7 from './Product (7).jpg';
import product8 from './Product (8).jpg';
import product9 from './Product (9).jpg';
import product10 from './Product (10).jpg';
import product11 from './Product (11).jpg';
import product12 from './Product (12).jpg';
import product13 from './Product (13).jpg';
import product14 from './Product (14).jpg';
import product15 from './Product (15).jpg';
import product16 from './Product (16).jpg';
import product17 from './Product (17).jpg';
import product18 from './Product (18).jpg';
import product19 from './Product (19).jpg';
import product20 from './Product (20).jpg';
import product21 from './Product (21).jpg';
import product22 from './Product (22).jpg';
import product23 from './Product (23).jpg';
import product24 from './Product (24).jpg';
import product25 from './Product (25).jpg';
import product26 from './Product (26).jpg';
import product27 from './Product (27).jpg';
import product28 from './Product (28).jpg';
import product29 from './Product (29).jpg';
import product30 from './Product (30).jpg';
import product31 from './Product (31).jpg';
import product32 from './Product (32).jpg';
import product33 from './Product (33).jpg';
import product34 from './Product (34).jpg';
import product35 from './Product (35).jpg';
import product36 from './Product (36).jpg';
import product37 from './Product (37).jpg';
import product38 from './Product (38).jpg';
import product39 from './Product (39).jpg';
import product40 from './Product (40).jpg';
import product41 from './Product (41).jpg';
import product42 from './Product (42).jpg';
import product43 from './Product (43).jpg';
import product44 from './Product (44).jpg';
import product45 from './Product (45).jpg';
import product46 from './Product (46).jpg';
import product47 from './Product (47).jpg';
import product48 from './Product (48).jpg';
import product49 from './Product (49).jpg';
import product50 from './Product (50).jpg';
import product51 from './Product (51).jpg';
import product52 from './Product (52).jpg';
import product53 from './Product (53).jpg';
import product54 from './Product (54).jpg';
import product55 from './Product (55).jpg';
import product56 from './Product (56).jpg';
import product57 from './Product (57).jpg';
import product58 from './Product (58).jpg';
import product59 from './Product (59).jpg';
import product60 from './Product (60).jpg';
import product61 from './Product (61).jpg';
import product62 from './Product (62).jpg';
import product63 from './Product (63).jpg';
import product64 from './Product (64).jpg';
import product65 from './Product (65).jpg';
import product66 from './Product (66).jpg';
import product67 from './Product (67).jpg';
import product68 from './Product (68).jpg';
import product69 from './Product (69).jpg';
import product70 from './Product (70).jpg';
import product71 from './Product (71).jpg';
import product72 from './Product (72).jpg';
import product73 from './Product (73).jpg';
import product74 from './Product (74).jpg';
import product75 from './Product (75).jpg';
import product76 from './Product (76).jpg';
import product77 from './Product (77).jpg';
import product78 from './Product (78).jpg';

const productImages = [
  { src: product1, title: 'Product 1' },
  { src: product2, title: 'Product 2' },
  { src: product3, title: 'Product 3' },
  { src: product4, title: 'Product 4' },
  { src: product5, title: 'Product 5' },
  { src: product6, title: 'Product 6' },
  { src: product7, title: 'Product 7' },
  { src: product8, title: 'Product 8' },
  { src: product9, title: 'Product 9' },
  { src: product10, title: 'Product 10' },
  { src: product11, title: 'Product 11' },
  { src: product12, title: 'Product 12' },
  { src: product13, title: 'Product 13' },
  { src: product14, title: 'Product 14' },
  { src: product15, title: 'Product 15' },
  { src: product16, title: 'Product 16' },
  { src: product17, title: 'Product 17' },
  { src: product18, title: 'Product 18' },
  { src: product19, title: 'Product 19' },
  { src: product20, title: 'Product 20' },
  { src: product21, title: 'Product 21' },
  { src: product22, title: 'Product 22' },
  { src: product23, title: 'Product 23' },
  { src: product24, title: 'Product 24' },
  { src: product25, title: 'Product 25' },
  { src: product26, title: 'Product 26' },
  { src: product27, title: 'Product 27' },
  { src: product28, title: 'Product 28' },
  { src: product29, title: 'Product 29' },
  { src: product30, title: 'Product 30' },
  { src: product31, title: 'Product 31' },
  { src: product32, title: 'Product 32' },
  { src: product33, title: 'Product 33' },
  { src: product34, title: 'Product 34' },
  { src: product35, title: 'Product 35' },
  { src: product36, title: 'Product 36' },
  { src: product37, title: 'Product 37' },
  { src: product38, title: 'Product 38' },
  { src: product39, title: 'Product 39' },
  { src: product40, title: 'Product 40' },
  { src: product41, title: 'Product 41' },
  { src: product42, title: 'Product 42' },
  { src: product43, title: 'Product 43' },
  { src: product44, title: 'Product 44' },
  { src: product45, title: 'Product 45' },
  { src: product46, title: 'Product 46' },
  { src: product47, title: 'Product 47' },
  { src: product48, title: 'Product 48' },
  { src: product49, title: 'Product 49' },
  { src: product50, title: 'Product 50' },
  { src: product51, title: 'Product 51' },
  { src: product52, title: 'Product 52' },
  { src: product53, title: 'Product 53' },
  { src: product54, title: 'Product 54' },
  { src: product55, title: 'Product 55' },
  { src: product56, title: 'Product 56' },
  { src: product57, title: 'Product 57' },
  { src: product58, title: 'Product 58' },
  { src: product59, title: 'Product 59' },
  { src: product60, title: 'Product 60' },
  { src: product61, title: 'Product 61' },
  { src: product62, title: 'Product 62' },
  { src: product63, title: 'Product 63' },
  { src: product64, title: 'Product 64' },
  { src: product65, title: 'Product 65' },
  { src: product66, title: 'Product 66' },
  { src: product67, title: 'Product 67' },
  { src: product68, title: 'Product 68' },
  { src: product69, title: 'Product 69' },
  { src: product70, title: 'Product 70' },
  { src: product71, title: 'Product 71' },
  { src: product72, title: 'Product 72' },
  { src: product73, title: 'Product 73' },
  { src: product74, title: 'Product 74' },
  { src: product75, title: 'Product 75' },
  { src: product76, title: 'Product 76' },
  { src: product77, title: 'Product 77' },
  { src: product78, title: 'Product 78' },
]

export default productImages;

