import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import logo from '../../assets/logo.png'; // Adjust the path to your logo image

const logoStyle = {
  width: '140px',
  height: 'auto',
};

function ContactDetails() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="body2" fontWeight={600}>
        Contact Details
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Address: 74 Oxford Road, Denham, Uxbridge, England, UB9 4DN
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Phone: +447438540590
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Email: info@500sprl.co.uk
      </Typography>
    </Box>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="https://500sprl.com/" color="inherit">500% SPRL&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 4, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ ml: '-15px' }}>
            <img
              src={logo}
              style={logoStyle}
              alt="logo of 500sprl"
            />
          </Box>
          <Typography variant="body2" fontWeight={600} gutterBottom>
            Newsletter
          </Typography>
          <Typography variant="body2" color="text.secondary" mb={2}>
            Subscribe to our newsletter for weekly updates and promotions.
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              fullWidth
              aria-label="Enter your email address"
              placeholder="Your email address"
              inputProps={{
                autoComplete: 'off',
                'aria-label': 'Enter your email address',
              }}
            />
            <Button variant="contained" color="primary" sx={{ flexShrink: 0 }}>
              Subscribe
            </Button>
          </Stack>
        </Box>
        
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 2,
            minWidth: 'auto',
          }}
        >
          <ContactDetails />
        </Box>
        
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
          flexDirection: { xs: 'column', sm: 'row' },
          textAlign: 'center',
        }}
      >
        <div>
          <Link href="/privacy-policy" color="text.secondary" sx={{ mx: 1 }}>
            Privacy Policy
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link href="/terms-and-conditions" color="text.secondary" sx={{ mx: 1 }}>
            Terms of Service
          </Link>
        </div>
        <Typography variant="body2" color="text.secondary" mt={{ xs: 2, sm: 0 }}>
          Design by{' '}
          <Link href="https://thewebpal.com" target="_blank" rel="noopener noreferrer" color="inherit">
            THEWEBPAL
          </Link>
        </Typography>
        <Copyright />
      </Box>
    </Container>
  );
}

export default Footer;
