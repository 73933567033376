// src/pages/Contact.js
import React from 'react';
import ContactPage from '../Components/ContactUs/ContactPage';

const Contact = () => {
  return (
    <div>
      <br/>
      <br/>
      <ContactPage />
      
    </div>
  );
};

export default Contact;
