// src/components/Navbar.js
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import MenuIcon from '@mui/icons-material/Menu';

import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';

const Navbar = () => {
  
  const [open, setOpen] = React.useState(false);

  

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 2,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            borderRadius: '999px',
            bgcolor:
              theme.palette.mode === 'light'
                ? '#b0bec5'
                : 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(24px)',
            maxHeight: 40,
            border: '1px solid',
            borderColor: 'divider',
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
          })}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              ml: '-18px',
              px: 0,
            }}
          >
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
              <img src="/logo.png" alt="logo" style={{ height: 50, marginRight: 10 }} />
              <Typography variant="h6" component="div">
                500% SPRL UK
              </Typography>
            </Link>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant="button" sx={{ marginRight: 2 }}>Home</Typography>
            </Link>
            <Link to="/service" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant="button" sx={{ marginRight: 2 }}>Service</Typography>
            </Link>
            <Link to="/product" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant="button" sx={{ marginRight: 2 }}>Product</Typography>
            </Link>
            <Link to="/aboutus" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant="button" sx={{ marginRight: 2 }}>About</Typography>
            </Link>
            <Link to="/terms-and-conditions" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant="button" sx={{ marginRight: 2 }}>Terms</Typography>
            </Link>
            <Link to="/privacy-policy" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant="button" sx={{ marginRight: 2 }}>Privacy</Typography>
            </Link>
          </Box>
         
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 0.5, alignItems: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              component={Link}
              to="/contact"
            >
              Contact
            </Button>
           
          </Box>
          <Box sx={{ display: { sm: '', md: 'none' } }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: '30px', p: '4px' }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: '60dvw',
                  p: 2,
                  backgroundColor: 'background.paper',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    flexGrow: 1,
                  }}
                >
                  {/* Add any additional drawer items here */}
                </Box>
                <MenuItem onClick={() => setOpen(false)}>
                  <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Home</Link>
                </MenuItem>
                <MenuItem onClick={() => setOpen(false)}>
                  <Link to="/service" style={{ textDecoration: 'none', color: 'inherit' }}>Service</Link>
                </MenuItem>
                <MenuItem onClick={() => setOpen(false)}>
                  <Link to="/product" style={{ textDecoration: 'none', color: 'inherit' }}>Product</Link>
                </MenuItem>
                <MenuItem onClick={() => setOpen(false)}>
                  <Link to="/aboutus" style={{ textDecoration: 'none', color: 'inherit' }}>About</Link>
                </MenuItem>
                
                <Divider />
                <MenuItem>
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to="/contact"
                    sx={{ width: '100%' }}
                  >
                    Contact
                  </Button>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => setOpen(false)}>
                  <Link to="/terms-and-conditions" style={{ textDecoration: 'none', color: 'inherit' }}>Terms and conditions</Link>
                </MenuItem>
                <MenuItem onClick={() => setOpen(false)}>
                  <Link to="/privacy-policy" style={{ textDecoration: 'none', color: 'inherit' }}>Privacy Policy</Link>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
