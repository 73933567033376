// src/pages/TermsOfService.js
import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const TermsOfService = () => {
  return (
    <>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="body1" gutterBottom>
        Effective Date: 12 July 2024.
      </Typography>

      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to 500% SPRL. By accessing our website and using our services, you agree to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern 500% SPRL's relationship with you in relation to this website.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          2. Use of Our Services
        </Typography>
        <Typography variant="body1" paragraph>
          By using our website, you represent and warrant that you are at least 18 years old and possess the legal authority to enter into this agreement. You agree to use our services only for lawful purposes and in accordance with these Terms of Service.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          3. User Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          To access certain features of our website, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          4. Intellectual Property Rights
        </Typography>
        <Typography variant="body1" paragraph>
          All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of 500% SPRL or its content suppliers and is protected by international copyright laws. Unauthorized use of this content is prohibited.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          5. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We may terminate or suspend your access to our services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms of Service.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          6. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          In no event shall 500% SPRL, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; and (iii) any bugs, viruses, or other harmful code transmitted to or through our service by any third party.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          7. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These terms shall be governed and construed in accordance with the laws of England, without regard to its conflict of law provisions.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          8. Changes to These Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms, please contact us at support@500sprl.com or by post to:
        </Typography>
        <Typography variant="body1">
          500% SPRL<br />
          74 Oxford Road<br />
          Denham, Uxbridge, England, UB9 4DN<br />
        </Typography>
      </Box>
    </Container>
    </>
  );
};

export default TermsOfService;
