import React from 'react';
import { Container, Grid, Typography, Box, TextField, Button, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';

const MapContainer = styled('iframe')({
  width: '100%',
  height: '300px',
  border: 0,
  borderRadius: '4px',
  marginTop: '16px',
});

const ContactPage = () => {
  return (
    <Container sx={{ py: 8 }}>
      <Typography variant="h2" align="center" gutterBottom>
        Contact Us
      </Typography>
      
      <Grid container spacing={4}>
        {/* Contact Details */}
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
            
              <Typography variant="h5" gutterBottom>Contact Details</Typography>
              <Typography variant="body1" gutterBottom>Address: 74 Oxford Road, Denham, Uxbridge, England, UB9 4DN</Typography>
              <Typography variant="body1" gutterBottom>Phone: +447438540590</Typography>
              <Typography variant="body1" gutterBottom>Email: info@500sprl.co.uk</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Contact Form */}
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>Leave Massage</Typography>
              <Box component="form" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Subject"
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Message"
                      variant="outlined"
                      multiline
                      rows={4}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" type="submit">
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Map */}
      <Box sx={{ mt: 8 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Our Location
        </Typography>
        <MapContainer
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2498.196142358574!2d-0.5038329!3d51.5719895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48766c034fb78d89%3A0xb8f570e70d1955b6!2s74%20Oxford%20Rd%2C%20Denham%2C%20Uxbridge%20UB9%204DN%2C%20UK!5e0!3m2!1sen!2sus!4v1687211446141!5m2!1sen!2sus"
          loading="lazy"
        />
      </Box>
    </Container>
  );
};

export default ContactPage;
