// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Contact from './Pages/ContactUs';
import Product from './Pages/Product';
import Service from './Pages/Service';
import PrivacyPolicy from './Pages/Terms/Privecy';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import CTA from './Components/CTA';
import TermsOfService from './Pages/Terms/Terms';
import Error from './Components/Error/Error';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product" element={<Product />} />
        <Route path="/service" element={<Service />} />
        <Route path="/terms-and-conditions" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="*" element={<Error />} />
      </Routes>
      <CTA/>
      <Footer />
    </Router>
  );
};

export default App;
