// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import hero from '../../assets/Hero.jpg';

const Hero = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundImage: `url(${hero})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        textAlign: 'center',
      }}
    >
      <Box sx={{ p: 10, backgroundColor: 'rgba(0, 0, 0, 0.7)', borderRadius: 5 }}>
        <Typography variant="h3" component="h4" gutterBottom>
          Revitalize Your Business with Premium Household Essentials
        </Typography>
        <Button variant="contained" 
                color="primary" 
                size="large" 
                component={Link} to="/contact">
          Get Started
        </Button>
        
      </Box>
    </Box>
  );
};

export default Hero;
