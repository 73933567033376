// src/components/Reviews.js
import React from 'react';
import { Box, Typography, Grid, Rating, Avatar, Divider } from '@mui/material';
import johnDoeImage from '../../assets/reviewers/john-doe.jpg';
import emilySmithImage from '../../assets/reviewers/emily-smith.jpg';

const reviews = [
  {
    rating: 5,
    content: "The quality of soft drinks provided by 500% SPRL is unmatched. Our customers love the variety and freshness. Highly recommend!",
    reviewer: "John Doe",
    title: "Business Owner",
    image: johnDoeImage, // Replace with path to John Doe's image
  },
  {
    rating: 5,
    content: "I am impressed with the professionalism and efficiency of 500% SPRL. The deliveries are always on time. Fantastic service!",
    reviewer: "Emily Smith",
    title: "Business Owner",
    image: emilySmithImage, // Replace with path to Emily Smith's image
  }
];

const Reviews = () => {
  return (
    <Box sx={{ py: 15, px: 5, backgroundColor: '#f9f9f9' }}>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        CLIENT REVIEWS
      </Typography>
      {reviews.map((review, index) => (
        <Box key={index} sx={{ mb: 4 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2} align="center">
              <Avatar
                src={review.image}
                alt={review.reviewer}
                sx={{ width: 100, height: 100 }}
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography variant="body1" gutterBottom>
                <Rating name="read-only" value={review.rating} readOnly />
              </Typography>
              <Typography variant="body1" paragraph>
                {review.content}
              </Typography>
              <Typography variant="body1">
                <strong>{review.reviewer}</strong>, {review.title}
              </Typography>
            </Grid>
          </Grid>
          {index < reviews.length - 1 && <Divider sx={{ mt: 3, mb: 2 }} />}
        </Box>
      ))}
    </Box>
  );
};

export default Reviews;
