// src/components/WhyUs.js
import React from 'react';
import { Box, Grid, Typography, Paper, useMediaQuery, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const WhyUs = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const values = [
    {
      icon: <CheckCircleIcon fontSize="large" color="primary" />,
      title: "Quality Assurance",
      description: "We guarantee the freshness and superior quality of all our brands, ensuring customer satisfaction every time."
    },
    {
      icon: <LocalShippingIcon fontSize="large" color="primary" />,
      title: "Prompt Delivery",
      description: "Our efficient delivery services ensure that you receive your orders on time, without any delays or hassles."
    },
    {
      icon: <AttachMoneyIcon fontSize="large" color="primary" />,
      title: "Competitive Pricing",
      description: "We offer the best prices in the market, allowing your business to save while stocking up on top-notch products."
    }
  ];

  return (
    <Box sx={{ py: 15, px: 2, backgroundColor: '#f9f9f9' }}>
      <Typography variant="h6" component="h6" gutterBottom align="center">
        OUR CORE VALUES
      </Typography>
      <Typography variant="h4" component="h3" gutterBottom align="center" sx={{ mb: 4 }}>
        Why Choose 500% SPRL?
      </Typography>
      <Grid container spacing={isSmallScreen ? 10 : 4} justifyContent="center">
        {values.map((value, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                textAlign: 'center',
                height: '100%',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 6,
                }
              }}
            >
              <Box sx={{ mb: 2 }}>{value.icon}</Box>
              <Typography variant="h6" component="h4" gutterBottom>
                {value.title}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {value.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WhyUs;
